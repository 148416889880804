html, body {
	width: 100%;
	padding:0%;
	margin:0%;
	background-color:rgb(240, 240, 240);
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
	color:rgb(82, 82, 82);
	/* font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif; */
}

.nowrap {
	white-space: nowrap;
}

header {
	background-color: #00999d;
	color:white;
	font-weight:bold;
	font-size:150%;
	padding:10px 5px;
	text-align: center;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

main {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

@media (min-width: 800px) {
	header {
		padding:10px 30px;
		text-align: left;
	}
  main {
		flex-direction: row;
  }
}

/* form */
form {
	margin:10px auto;
	padding:10px 30px 10px 40px;
	width:70%;
	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

@media (min-width: 800px) {
  form {
		margin:20px 20px 20px 40px;
		width:30%;
  }
}

label {
	display: block;
	margin:20px 0px 4px;
	font-weight: bold;
	font-size:80%;
}

input[type=text] {
		border: none;
		border-bottom: 1px solid #00989d5e;
    padding: 8px;
		font-family: inherit;
		cursor: inherit;
		color: inherit;
		line-height: inherit;
}

select {
  background-color: transparent;
  border: none;
	border-bottom: 1px solid #00989d5e;
  padding: 4px;
  font-family: inherit;
	color: inherit;
  cursor: inherit;
  line-height: inherit;
}

button {
	display: block;
	font-size:120%;
  text-align: center;
  text-decoration: none;
	margin:20px auto;
	color:white;
	background-color: #f7b500;
  border: none;
  color: white;
  padding: 8px 32px;
	border-radius: 50px;
	transition-duration: 0.4s;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

button:hover, button:focus{
	background-color: #dfa300;
}

/* preview */
.preview-wrap {
	margin:10px auto;
}
@media (min-width: 800px) {
  .preview-wrap {
		margin:20px;
  }
}

h2 {
	margin: 0 0 10px 0;
	padding: 0 0 0 10px;
	border-left: 4px solid #00989d;
}

.preview-container{
	height:500px;
	width:350px;
	color:black;
	padding:0 25px;
	background-color: white;
	border-style: solid;
	border-color: #d6d6d6;
	border-width: 0.7px;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.head-square{
	margin:0 auto;
	width:100%;
	height:8px
}

.lead {
	font-family: Georgia, serif,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro";
	font-style: italic;
	font-size: 14px;
	margin: 0 auto;
	text-align: center;
}

.sub-title-container{
	font-size:25px;
}

.title-container{
	color:white;
	font-size: 40px;
	line-height: 120%;
	max-height: 100px;
	min-height: 50px;
	display:block;
	width:100%;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
	white-space: pre-line;
}

.author-container {
	margin-top:20px;
	float: right;
}

img {
	display:block;
	width:100%;
	margin:10px 0px -30px;
	object-fit: contain;
}

#color-select option {
	color:white;
	padding:5px auto;
}

/* ad */
.ads {
	margin:10px auto;
}
@media (min-width: 800px) {
  .ads {
		margin:20px;
  }
}